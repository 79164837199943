import React from 'react'
const RegularRoofIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="120.403" height="26.519" viewBox="0 0 120.403 26.519">
	  <defs>
	    <clipPath id="clip-path">
	      <path id="Path_15427" data-name="Path 15427" d="M25.65.4,84.766,2.617s17.6,5.5,23.271,7.673c7.294,2.745,7.966,5.968,7.966,5.968,1.411,3.9-1.013,3.871-1.013,3.871l-46.3,5.934.193-4.987c0-6.164-4.791-8.372-7.966-9.429C52.892,8.951,28.688.963,28.688.963Z" transform="translate(-25.65 -0.4)" fill="none"/>
	    </clipPath>
	  </defs>
	  <g id="Group_2350" data-name="Group 2350" transform="translate(-1080 -204.938)">
	    <g id="Group_2348" data-name="Group 2348" transform="translate(1080 205)">
	      <path id="Path_15415" data-name="Path 15415" d="M48.5,6.292c-31.287-9.617-10.446-3.41-10.446-3.41L30.727.316a2.8,2.8,0,0,0-2.833.119C23.341,2.413,8.127,7.955,2.551,10.9.605,11.936-.146,14.443,0,17.4H71.149S79.788,15.909,48.5,6.292Z" transform="translate(0.016 -0.004)" fill={props.fill}/>
	      <path id="Path_15416" data-name="Path 15416" d="M25.65.4,84.766,2.617s17.6,5.5,23.271,7.673c7.294,2.745,7.966,5.968,7.966,5.968,1.411,3.9-1.013,3.871-1.013,3.871l-46.3,5.934.193-4.987c0-6.164-4.791-8.372-7.966-9.429C52.892,8.951,28.688.963,28.688.963Z" transform="translate(3.556 -0.062)" fill="#fff"/>
	      <g id="Group_2344" data-name="Group 2344" transform="translate(29.206 0.338)" clip-path="url(#clip-path)">
	        <path id="Path_15417" data-name="Path 15417" d="M75.76,48.784,68.5,45.468,135.729,34.99l3.357,1.918Z" transform="translate(-19.739 -15.535)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15418" data-name="Path 15418" d="M69.866,45.125,62.39,41.706l70.939-8.986,3.414,1.952Z" transform="translate(-20.582 -15.2)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15419" data-name="Path 15419" d="M63.815,41.358,56.1,37.837l74.8-7.417,3.482,1.978Z" transform="translate(-21.45 -14.861)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15420" data-name="Path 15420" d="M57.574,37.5,49.62,33.85l78.836-5.78,3.528,2.021Z" transform="translate(-22.344 -14.514)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15421" data-name="Path 15421" d="M51.166,33.525,42.95,29.757l83.024-4.067,3.6,2.055Z" transform="translate(-23.264 -14.163)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15422" data-name="Path 15422" d="M44.558,29.435,36.08,25.548l87.394-2.268,3.653,2.08Z" transform="translate(-24.211 -13.808)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15423" data-name="Path 15423" d="M37.751,25.228,29,21.212l91.946-.392,3.721,2.123Z" transform="translate(-25.188 -13.445)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15424" data-name="Path 15424" d="M30.735,20.63,21.7,16.47l96.691,1.577,3.778,2.166Z" transform="translate(-26.195 -12.804)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15425" data-name="Path 15425" d="M22.5,3.886,19.267,2.4a1.174,1.174,0,0,1-.8-1.521A1.8,1.8,0,0,1,20.3,0l94.574,3.41a1.4,1.4,0,0,1,.637.171h0c1.036.6.546,1.986-.683,1.969L23.421,4.091a2.329,2.329,0,0,1-.922-.2Z" transform="translate(-26.653 -0.339)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	        <path id="Path_15426" data-name="Path 15426" d="M16.041,10.839,6.38,6.38,113.2,12.2l3.915,2.234Z" transform="translate(-28.308 -11.316)" fill="none" stroke="#686c79" strokeWidth="0.8"/>
	      </g>
	      <path id="Path_15428" data-name="Path 15428" d="M25.65.4,84.766,2.617s17.6,5.5,23.271,7.673c7.294,2.745,7.966,5.968,7.966,5.968,1.411,3.9-1.013,3.871-1.013,3.871l-46.3,5.934.193-4.987c0-6.164-4.791-8.372-7.966-9.429C52.892,8.951,28.688.963,28.688.963Z" transform="translate(3.556 -0.062)" fill="none" stroke="#686c79" stroke-miterlimit="10" strokeWidth="0.8"/>
	    </g>
	    <path id="Path_15459" data-name="Path 15459" d="M0,0H70.552A.968.968,0,0,1,71.2.192a1.206,1.206,0,0,1,.238.687L0,.852Z" transform="translate(1080 222.051)" fill={props.fill}/>
	  </g>
	</svg>
)
export default RegularRoofIcon